import styled from 'styled-components';
import { P as BaseP, StyledOL } from '~/components/BaseElements';

export const P = styled(BaseP)`
  font-size: 1.1rem;
  line-height: 1.6;
`;

export const OL = styled(StyledOL)`
  padding-left: 15px;
  font-size: 1.1rem;
  line-height: 1.6;
`;
