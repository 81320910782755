import React from 'react';
import { ExternalLink, InternalLink, Section } from '~/components/BaseElements';
import { Trans, useTranslation } from 'react-i18next';
import * as Styled from './styles';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Hero from '~/components/Hero/index.tsx';
import { ROUTES } from '~/config';

const TermAndCond = () => {
  const { t } = useTranslation();

  const boldNecessaryText = key => {
    return t(key)
      .replace(/"STOP"/g, '<strong>"STOP"</strong>')
      .replace(/"HELP"/g, '<strong>"HELP"</strong>')
      .replace(/"ARRET"/g, '<strong>"ARRET"</strong>')
      .replace(/"AIDE"/g, '<strong>"AIDE"</strong>');
  };

  return (
    <Section py={0}>
      <Hero isSubPage>{t('TermsAndCondition.title')}</Hero>
      <Box py={8}>
        <Container>
          <Grid item md={8}>
            <Styled.P>
              <Trans i18nKey="TermsAndCondition.p1">
                <ExternalLink to="https://diseasedaily.org/about/terms-of-use/">
                  {t('TermsAndCondition.healthMapTermsOfUse')}
                </ExternalLink>
              </Trans>
            </Styled.P>
            <Styled.P>
              <strong>{t('TermsAndCondition.p2')}</strong>
            </Styled.P>
            <Styled.OL>
              <li>{t('TermsAndCondition.li1')}</li>
              <li>{t('TermsAndCondition.li2')}</li>
              <li
                dangerouslySetInnerHTML={{
                  __html: boldNecessaryText('TermsAndCondition.li3'),
                }}
              ></li>
              <li
                dangerouslySetInnerHTML={{
                  __html: boldNecessaryText('TermsAndCondition.li4'),
                }}
              ></li>
              <li>{t('TermsAndCondition.li5')}</li>
              <li>
                {t('TermsAndCondition.li6')}
                <ExternalLink to="mailto:info@outbreaksnearme.org">
                  info@outbreaksnearme.org
                </ExternalLink>
                .
              </li>
              <li>
                <Trans i18nKey="TermsAndCondition.li7">
                  <InternalLink to={ROUTES.privacyPolicy}>
                    {t('Links.privacyPolicy')}
                  </InternalLink>
                </Trans>
              </li>
            </Styled.OL>
          </Grid>
        </Container>
      </Box>
    </Section>
  );
};

export default TermAndCond;
