import React from 'react';

import SEO from '~/components/seo';
import TermAndCond from '~/components/TermsAndCondition';

const TermsAndCondition = props => (
  <>
    <SEO title="TermsAndCondition.title" pageContext={props.pageContext} />
    <TermAndCond />
  </>
);

export default TermsAndCondition;
